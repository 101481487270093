<template>
    <auth-layout>
        <auth-form back-route="auth-registration" :action="action" :callback="submit">
            <template #header>
                <h1>{{ $t('auth_registration_code.title') }}</h1>
                <p>
                    {{ $t('auth_registration_code.subtitle') }}
                    <strong>{{ email }}</strong>
                </p>
            </template>

            <validation-provider name="code" rules="required|min:6" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_registration_code.code.label')" :errors="errors">
                    <verification-code v-model="code" @input="reset" />
                </input-group>
            </validation-provider>

            <text-button
                type="button"
                :theme="isTimerStarted || resendCodeLoading ? 'secondary' : 'primary'"
                full
                :loading="resendCodeLoading"
                :disabled="isTimerStarted || resendCodeLoading"
                @click="resendCode"
            >
                {{ $t('auth_registration_code.resend_code_button') }}
                <timer v-if="isTimerStarted" :value="timer" inline @completed="clearTimer" />
            </text-button>

            <template #button>
                {{ $t('auth_registration_code.submit_button') }}
                <arrow-right-icon />
            </template>
        </auth-form>
    </auth-layout>
</template>

<script>
    import { DateTime } from 'luxon';
    import { ValidationProvider } from 'vee-validate';
    import AuthForm from '@/components/auth/AuthForm';
    import TextButton from '@/components/buttons/TextButton';
    import ArrowRightIcon from '@/components/icons/ArrowRightIcon';
    import InputGroup from '@/components/inputs/InputGroup';
    import VerificationCode from '@/components/inputs/VerificationCode';
    import Timer from '@/components/Timer';
    import Toast from '@/components/Toast';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import { resendCode, verifyEmail } from '@/services/skinsPaymentApi';
    import { REGISTRATION_CODE_TIMEOUT } from '@/services/skinsPaymentApi/constants/common';

    export default {
        name: 'RegistrationCode',
        components: {
            ValidationProvider,
            AuthForm,
            TextButton,
            ArrowRightIcon,
            InputGroup,
            VerificationCode,
            Timer,
            AuthLayout,
        },
        computed: {
            action() {
                const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');

                return `landing-api/applications/${applicationId}/verify-email`;
            },
            email() {
                return sessionStorage.getItem('AUTH_REGISTRATION_EMAIL');
            },
            isTimerStarted() {
                return this.timer > 0;
            },
        },
        methods: {
            checkTimer() {
                const endTs = Number(sessionStorage.getItem('AUTH_REGISTRATION_TIMER_END'));
                const nowTs = DateTime.now().toUnixInteger();

                if (endTs > nowTs) {
                    this.timer = endTs - nowTs;
                } else {
                    sessionStorage.removeItem('AUTH_REGISTRATION_TIMER_END');
                }
            },
            clearTimer() {
                this.timer = 0;
                sessionStorage.removeItem('AUTH_REGISTRATION_TIMER_END');
            },
            resendCode() {
                const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');

                this.resendCodeLoading = true;

                resendCode(applicationId)
                    .then(() => {
                        this.timer = REGISTRATION_CODE_TIMEOUT;

                        sessionStorage.setItem(
                            'AUTH_REGISTRATION_TIMER_END',
                            DateTime.now().plus({ seconds: REGISTRATION_CODE_TIMEOUT }).toUnixInteger()
                        );

                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'success',
                                text: this.$t('auth_registration_code.toasts.resend_code_success'),
                            },
                        });
                    })
                    .finally(() => {
                        this.resendCodeLoading = false;
                    });
            },
            submit() {
                const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');

                return verifyEmail(applicationId, this.code).then(({ data }) => {
                    sessionStorage.setItem('AUTH_REGISTRATION_FILE_NAME', data.file.name);
                    sessionStorage.setItem('AUTH_REGISTRATION_FILE_PATH', data.file.path);
                    sessionStorage.setItem('AUTH_REGISTRATION_FILE_URL', data.file.url);
                    sessionStorage.setItem('AUTH_REGISTRATION_NS', data.ns);
                    sessionStorage.setItem('AUTH_REGISTRATION_TAG', data.tag);

                    this.$toast({
                        component: Toast,
                        props: {
                            type: 'success',
                            text: this.$t('auth_registration_code.toasts.submit_success'),
                        },
                    });

                    this.$router.push({ name: 'auth-registration-domain' });
                });
            },
        },
        data() {
            return {
                code: '',
                timer: 0,
                resendCodeLoading: false,
            };
        },
        beforeRouteEnter(to, from, next) {
            const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');
            const email = sessionStorage.getItem('AUTH_REGISTRATION_EMAIL');

            if (applicationId === null || email === null) {
                next({ name: 'auth-registration' });
            } else {
                next();
            }
        },
        created() {
            this.checkTimer();
        },
    };
</script>
